// Frontend Data table design and other config applied like pagination, shorting and so on 

export default class Global_Table_Config {
    table_configuration($, i, elem, table_name, table_settings, sheetUrl) {
        let dom = `<"filtering_input filtering_input_${i}"${
                table_settings?.show_x_entries ? "l" : ""
            }${
                table_settings?.search_bar ? "f" : ""
            }>rt<"bottom_options bottom_options_${i}"${
                table_settings?.show_info_block ? "i" : ""
            }${  table_settings?.pagination ? "p" : ""}>`;

        if (this.isProPluginActive()) {
            dom = `B<"filtering_input filtering_input_${i}"${
                table_settings?.show_x_entries ? "l" : ""
            }${
                table_settings?.search_bar ? "f" : ""
            }>rt<"bottom_options bottom_options_${i}"${
                table_settings?.show_info_block ? "i" : ""
            }${  table_settings?.pagination ? "p" : ""}>`;

            // change the cell format according to feature saved at database
            this.changeRedirectionType(
                table_settings?.redirection_type,
                $(elem).find("#create_tables a"),
                $
            );
        }

         // For free it need to use without pro checked
         this.changeCellFormat(
            table_settings?.cell_format,
            $(elem).find("#create_tables th, td"),
            $
        );

        if ( $.fn.DataTable && $(elem).find("#create_tables").length ) {
            $(elem).find("#create_tables").DataTable(this.table_obj($, table_name, table_settings, dom));
        }

        this.setPdfUrl(sheetUrl, $(elem).find(".dt-buttons"));

        if (this.isProPluginActive()) {
            this.reveal_export_btns($, elem, table_settings);
        }

        this.swap_input_filter($, i, table_settings);

        this.swap_bottom_options($, i, table_settings);
    }

    changeRedirectionType(type, links, $) {
        if (!links.length) return;
        $.each(links, function (i, link) {
            $(link).attr("target", type);
        });
    }

    changeCellFormat(formatStyle, tableCell, $) {
        switch (formatStyle) {
            case "wrap":
                $.each(tableCell, function (i, cell) {
                    $(cell).removeClass("clip_style");
                    $(cell).removeClass("expanded_style");
                    $(cell).addClass("wrap_style");
                });
                break;

            case "clip":
                $.each(tableCell, function (i, cell) {
                    $(cell).removeClass("wrap_style");
                    $(cell).removeClass("expanded_style");
                    $(cell).addClass("clip_style");
                });
                break;
            case "expand":
                $.each(tableCell, function (i, cell) {
                    $(cell).removeClass("clip_style");
                    $(cell).removeClass("wrap_style");
                    $(cell).addClass("expanded_style");
                });
                break;

            default:
                break;
        }
    }

    isProPluginActive() {
        if (front_end_data.isProActive || front_end_data.isProActive != '') {
            return true;
        } else {
            return false;
        }
    }

    getSpreadsheetID(url) {
        if (!url || url == "") return;

        let sheetID = null;

        sheetID = url.split(/\//)[5];

        if (sheetID) return sheetID;

        return null;
    }

    getGridID(url) {
        if (!url || url == "") return;

        let gridID = null;

        gridID = url.match(/gid=(\w+)/);

        if ( gridID != null ) {
            gridID = gridID[1];
        }

        if (gridID) {
            return gridID;
        }

        return null;
    }

    //PDF Export as PDF
    setPdfUrl(url, elem) {
        let spreadsheetID = this.getSpreadsheetID(url);
        let gridID = this.getGridID(url);
        let pdfUrl = `https://docs.google.com/spreadsheets/d/${spreadsheetID}/export?format=pdf&id=${spreadsheetID}&gid=${gridID}`;
        // Forntend part design `<a class="ui dt-button inverted button transition pdf_btn"
        elem.append(
            `<a class="ui inverted button export_btns pdf_btn"
                href="${pdfUrl}"
                download
                title="${front_end_data.strings['export-pdf']}"
                >
                <span>
                    <img src="${front_end_data.iconsURL.filePdf}" />
                </span>
            </a>`
        );
    }
    
    table_obj($, table_name, table_settings, dom) {

        let table_object = {
            dom: dom,
            order: [],
            // order: [[0, 'asc']],
            responsive: true,
            lengthMenu: [
                [1, 5, 10, 15, 30],
                [
                    front_end_data.strings['1'],
                    front_end_data.strings['5'],
                    front_end_data.strings['10'],
                    front_end_data.strings['15'],
                    front_end_data.strings['30'],
                ]
            ],
            language: {
                search: front_end_data.strings['search'],
                searchPlaceholder: "Search items",
                lengthMenu: front_end_data.strings['filtering_show'] + " _MENU_ " + front_end_data.strings['filtering_entries'],
                info: front_end_data.strings['dataTables_info_showing'] + " _START_ " + front_end_data.strings['dataTables_info_to'] + " _END_ " + front_end_data.strings['dataTables_info_of'] + " _TOTAL_ " + front_end_data.strings['filtering_entries'],

                emptyTable: front_end_data.strings['data-empty-notice'],
                zeroRecords: front_end_data.strings['data-empty-notice'],
                paginate: {
                    first: "first",
                    previous: front_end_data.strings['previous'],
                    next: front_end_data.strings['next'],
                    last: front_end_data.strings['last'],
                },
            },
            pageLength: parseInt(table_settings.default_rows_per_page),
            lengthChange: true,
            ordering: table_settings.allow_sorting,
            destroy: true,
            scrollX: true,
        };

        $('.gswpts_tables_container').each(function () {
            let numberOfColumns = $(this).find('thead th').length;
            let table_object = {
                order: []
            };
        
            for (let i = 0; i < numberOfColumns; i++) {
                // table_object.order.push([i, 'asc']);
                table_object.order.push([i, '']);
            }
        });

        if (this.isProPluginActive()) {
            table_object.buttons = [
                {
                    text: `<img src="${front_end_data.iconsURL.curlyBrackets}" />`,
                    className: "ui inverted button export_btns json_btn",
                    action: function (e, dt, button, config) {
                        var data = dt.buttons.exportData();
                        $.fn.dataTable.fileSave(
                            new Blob([JSON.stringify(data)]),
                            `${table_name}.json`
                        );
                    },
                    titleAttr: front_end_data.strings['export-json']
                },
                {
                    text: `<img src="${front_end_data.iconsURL.fileCSV}" />`,
                    extend: "csv",
                    className: "ui inverted button export_btns csv_btn",
                    title: `${table_name}`,
                    // titleAttr: "Export as CSV",
                    titleAttr: front_end_data.strings['export-csv'],
                },
                {
                    text: `<img src="${front_end_data.iconsURL.fileExcel}" />`,
                    extend: "excel",
                    className: "ui inverted button export_btns excel_btn",
                    title: `${table_name}`,
                    titleAttr: front_end_data.strings['export-excel']
                },
                {
                    text: `<img src="${front_end_data.iconsURL.printIcon}" />`,
                    extend: "print",
                    className: "ui inverted button export_btns print_btn",
                    title: `${table_name}`,
                    titleAttr: front_end_data.strings['print']
                },
                {
                    text: `<img src="${front_end_data.iconsURL.copySolid}" />`,
                    extend: "copy",
                    className: "ui inverted button export_btns copy_btn",
                    title: `${table_name}`,
                    titleAttr: front_end_data.strings['copy']
                },
            ];

            table_object.lengthMenu = [
                [1, 5, 10, 15, 30, 50, 100, -1],
                [
                    front_end_data.strings['1'],
                    front_end_data.strings['5'],
                    front_end_data.strings['10'],
                    front_end_data.strings['15'],
                    front_end_data.strings['30'],
                    front_end_data.strings['50'],
                    front_end_data.strings['100'],
                    front_end_data.strings['All']
                ]
            ];

            // Its now free  
            /* if (table_settings.vertical_scrolling) {
                table_object.scrollY = `${table_settings.vertical_scrolling}px`;
            } */

 
            //Column is needed to hide and backend code is not used actually to hide.
            if ((table_settings?.hide_on_desktop_col && this.screenSize() === "desktop") || (table_settings?.hide_on_mobile_col && this.screenSize() === "mobile")) {
                if (this.screenSize() === "desktop" && table_settings.hide_column) {
                    table_object.columnDefs = this.hideColumnByScreen(
                        table_settings.hide_column
                    );
                } else if (this.screenSize() === "mobile" && table_settings.hide_column_mobile) {
                    table_object.columnDefs = this.hideColumnByScreen(
                        table_settings.hide_column_mobile
                    );
                }
            }

            // Row not needed now becasue  backend code is mainly used to hide 
            /* if ((!table_settings?.hide_on_desktop_rows && !table_settings?.hide_on_mobile_rows) || (table_settings?.hide_on_desktop_rows && this.screenSize() === "desktop") || (table_settings?.hide_on_mobile_rows && this.screenSize() === "mobile")) {
                if (this.screenSize() === "desktop" && table_settings.hide_rows) {
                    table_object.rowDefs = this.hideRowByScreen(
                        table_settings.hide_rows
                    );
                } else if (this.screenSize() === "mobile" && table_settings.hide_rows_mobile) {
                    table_object.rowDefs = this.hideRowByScreen(
                        table_settings.hide_rows_mobile
                    );
                }
            } */

            // cell not needed now becasue  backend code is mainly used to hide 
            /* if ((!table_settings?.hide_on_desktop_cell && !table_settings?.hide_on_mobile_cell) || (table_settings?.hide_on_desktop_cell && this.screenSize() === "desktop") || (table_settings?.hide_on_mobile_cell && this.screenSize() === "mobile")) {
                if (this.screenSize() === "desktop" && table_settings.hide_cell) {
                    table_object.cellDefs = this.hideCellByScreen(
                        table_settings.hide_cell
                    );
                } else if (this.screenSize() === "mobile" && table_settings.hide_cell_mobile) {
                    table_object.cellDefs = this.hideCellByScreen(
                        table_settings.hide_cell_mobile
                    );
                }
            } */
            
 
        }
        
        // Table height 
        if (table_settings.vertical_scrolling) {
            table_object.scrollY = `${table_settings.vertical_scrolling}px`;
        }

        return table_object;
    }

    // Return an array that will define the columns to hide
    hideColumnByScreen(arrayValues) {
        return [
            {
                targets: this.convertArrayStringToInteger(arrayValues),
                visible: false,
                searchable: false,
            },
        ];
    }

    // get the current screen size of user if greater than 740 return desktop or return mobile
    screenSize() {
        // Desktop screen size
        if (screen.width > 740) {
            return "desktop";
        } else {
            return "mobile";
        }
    }

    // convert string to integer from arrays
    convertArrayStringToInteger(arr) {
        if (!arr) return [];
        return arr.map((val) => parseInt(val));
    }

    reveal_export_btns($, elem, table_settings) {
        if (!table_settings) return;

        let export_btns = table_settings.table_export;

        if (export_btns != "empty" && export_btns) {
            export_btns.forEach((btn) => {
                $(elem)
                    .find("." + btn + "_btn")
                    .removeClass("export_btns");
            });
        }
    }

    swap_input_filter($, i, table_settings) {
        /* If checkbox is checked then swap filter */
        if (table_settings?.swap_filter_inputs) {
            $(".filtering_input_" + i + "").css("flex-direction", "row-reverse");
            $(".filtering_input_" + i + " > #create_tables_length").css({
                "margin-right": "0",
                "margin-left": "auto",
            });
            $(".filtering_input_" + i + " > #create_tables_filter").css({
                "margin-left": "0",
                "margin-right": "auto",
            });
        } else {
            /* Set back to default position */
            $(".filtering_input_" + i + "").css("flex-direction", "row");
            $(".filtering_input_" + i + " > #create_tables_length").css({
                "margin-right": "auto",
                "margin-left": "0",
            });
            $(".filtering_input_" + i + " > #create_tables_filter").css({
                "margin-left": "auto",
                "margin-right": "0",
            });
        }
    }

    swap_bottom_options($, i, table_settings) {
        let style = {
            flex_direction: "row-reverse",
            table_info_style: {
                margin_left: "auto",
                margin_right: 0,
            },
            table_paginate_style: {
                margin_left: 0,
                margin_right: "auto",
            },
        };

        if (table_settings?.swap_bottom_options) {
            this.bottom_option_style($, style, i);
        } else {
            style["flex_direction"] = "row";

            style.table_info_style["margin_left"] = 0;
            style.table_info_style["margin_right"] = "auto";

            style.table_paginate_style["margin_left"] = "auto";
            style.table_paginate_style["margin_right"] = 0;

            this.bottom_option_style($, style, i);
        }
    }

    bottom_option_style($, $arg, i) {
        $(".bottom_options_" + i + "").css("flex-direction", $arg["flex_direction"]);
        $(".bottom_options_" + i + " > #create_tables_info").css({
            "margin-left": $arg["table_info_style"]["margin_left"],
            "margin-right": $arg["table_info_style"]["margin_right"],
        });
        $(".bottom_options_" + i + " > #create_tables_paginate").css({
            "margin-left": $arg["table_paginate_style"]["margin_left"],
            "margin-right": $arg["table_paginate_style"]["margin_right"],
        });
    }

    /**
     * Header merge 
     */
    handleHeaderMergedCells($, elem) {
        var tableHeaders = $(elem).find('.thead-item[data-merge]');
        
        if(tableHeaders){
            tableHeaders.each(function (index, header) {
                // Check if the current header has the data-merge attribute.
                var dataMerge = $(header).attr('data-merge');
        
                if (dataMerge) {
                    dataMerge = JSON.parse(dataMerge);
                    var startCol = dataMerge[0];
                    var numCols = dataMerge[1];
        
                    // Add parentCellstart class to the starting cell
                    $(header).addClass('parentCellstart');
                    
                    $(header).css({
                        'text-align': 'center',
                    });
        
                    // Update colspan attribute
                    $(header).attr('colspan', numCols);
        
                    // Get the next cell in the row
                    var nextCell = $(header).next();
        
                    // Loop through numCols starting from 1
                    for (var i = 1; i < numCols; i++) {
                        if (nextCell && nextCell.length) {
                            $(nextCell).addClass('childCell');
                            // nextCell.style.display = 'none'; 
                            nextCell = $(nextCell).next();
                        }
                    }
    
                    $(elem).find('.childCell').css('display', 'none');
    
                }
            });
        }
    }

    /**
     * Table body merge
     * @param {* table body merge} $ 
     * @param {*} elem 
     */

    handleMergedCells($, elem) {
        var tableRows = $(elem).find('.gswpts_rows');
    
        tableRows.each(function () {
            var cells = $(this).find('td');
    
            cells.each(function () {
                if ($(this).hasClass('parentCellstart')) {

                    // Apply the logic from the previous conversation here
                    var dataMerge = JSON.parse($(this).attr('data-merge'));
                    var dataIndex = JSON.parse($(this).attr('data-index'));
    
                    for (var i = 0; i < dataMerge[0]; i++) {
                        for (var j = 0; j < dataMerge[1]; j++) {
                            var targetIndex = [
                                dataIndex[0] + i,
                                dataIndex[1] + j
                            ];
    
                            var targetCell = $(elem).find('[data-index="[' + targetIndex.join(',') + ']"]');
    
                            if (targetCell) {
                                var cellDivContent = targetCell.find('.cell_div').text().trim();
                                if (cellDivContent === '') {
                                    targetCell.addClass('merged-cell');
                                    targetCell.hide();

                                    if ($(this).hasClass('parentCellstart')) {
                                        $(this).css({
                                            'text-align': 'center',
                                            'vertical-align': 'middle'
                                        });
                                    }
                                } else {
                                    targetCell.addClass('normal-cell');
                                }
                            }
                        }
                    }
                }
            });
        });
    }

    /**
     * 
     * Add dynamic table width in header from body td calculated on tr
     */
    handleTableWidth($, elem) {
        // Check if elem has a prevObject property
        if (elem.prevObject && elem.prevObject.length > 0) {
            elem = elem.prevObject;
        }

        // Ensure that the desired element is present in the jQuery object
        if (elem.length === 0) {
            console.log("No element found within the given object.");
            return;
        }

        // Loop through each element in elem (there should be only one)
        elem.each(function (index, container) {
            // Find the <tr> element within the container
            var row = $(container).find("tr.gswpts_rows");

            // Check if the <tr> element exists in the container
            if (row.length > 0) {
                // Get the bounding rectangle of the <tr> element
                var rect = row[0].getBoundingClientRect();

                // Get the total width from the bounding rectangle
                var totalWidth = rect.width;


                
                /* // Get the table element within the container
                var table = $(container).find(".ui.celled.display.table.gswpts_tables.dataTable.no-footer");

                // Set the width of the table
                table.css('width', totalWidth + "px");

                // Log the total width to the console
                console.log("Total Width for Table with ID " + container.id + ": " + totalWidth + " pixels"); */

                

                if (totalWidth === 0) { // when hide row feature active in first row of the table then this happen so to prevemt this 
                    // Set the width of the table to 100%
                    var table = $(container).find(".ui.celled.display.table.gswpts_tables.dataTable.no-footer");
                    table.css('width', '100%');
    
                    console.log("Total Width for Table with ID " + container.id + ": " + totalWidth + " pixels");
                    // Log the change to the console
                    console.log("Total Width for Table with ID " + container.id + " is 0 pixels. Setting width to 100%.");
                } else {
                    // Get the table element within the container
                    var table = $(container).find(".ui.celled.display.table.gswpts_tables.dataTable.no-footer");
    
                    // Set the width of the table
                    table.css('width', totalWidth + "px");
    
                    // Log the total width to the console
                    console.log("Total Width for Table with ID " + container.id + ": " + totalWidth + " pixels");
                }


            }
        });
    }

    html_loader() {
        let loader = `
               <div class="ui segment gswpts_table_loader">
                        <div class="ui active inverted dimmer">
                            <div class="ui large text loader">Loading</div>
                        </div>
                        <p></p>
                        <p></p>
                        <p></p>
                </div>
            `;
        return loader;
    }

    clearOverflow() {
        if (
            /Android|webOS|iPhone|iPad|Mac|Macintosh|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
            )
        ) {
            if (
                this.detectBrowser() == "Chrome" ||
                this.detectBrowser() == "Safari" ||
                this.detectBrowser() == "MSIE"
            ) {
                let tableScrollBody = document.querySelectorAll(
                    ".gswpts_tables_container .dataTables_scrollBody"
                );

                if (tableScrollBody) {
                    tableScrollBody.forEach((element) => {
                        if (
                            element.parentElement.parentElement.parentElement.parentElement.classList.contains(
                                "collapse_style"
                            )
                        ) {
                            element.style.overflow = "unset";
                            element.style.height = "unset";
                            element.style.maxHeight = "unset";
                        }
                    });
                }

                // this.hideEmptyCell();
            }
        }
    }

    detectBrowser() {
        if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf("OPR")) != -1) {
            return "Opera";
        } else if (navigator.userAgent.indexOf("Chrome") != -1) {
            return "Chrome";
        } else if (navigator.userAgent.indexOf("Safari") != -1) {
            return "Safari";
        } else if (navigator.userAgent.indexOf("Firefox") != -1) {
            return "Firefox";
        } else if (navigator.userAgent.indexOf("MSIE") != -1 || !!document.documentMode == true) {
            return "IE"; //crap
        } else {
            return "Unknown";
        }
    }

    hideEmptyCell() {
        let tableCells = document.querySelectorAll(".gswpts_tables_container td");
        if (!tableCells) return;

        tableCells.forEach((element) => {
            if (element.innerText == "") {
                // element.style.display = "none";
                element.innerText = "No data";
            }
        });
    }

    bindDragScroll($container, $scroller) {
        var $window = jQuery(window);

        var x = 0;
        var y = 0;

        var x2 = 0;
        var y2 = 0;
        var t = 0;

        $container.on("mousedown", down);
        $container.on("click", preventDefault);
        $scroller.on("mousewheel", horizontalMouseWheel); // prevent macbook trigger prev/next page while scrolling

        function down(evt) {
            if (evt.button === 0) {
                t = Date.now();
                x = x2 = evt.pageX;
                y = y2 = evt.pageY;

                $container.addClass("down");
                $window.on("mousemove", move);
                $window.on("mouseup", up);

                evt.preventDefault();
            }
        }

        function move(evt) {
            // alert("move");
            if ($container.hasClass("down")) {
                var _x = evt.pageX;
                var _y = evt.pageY;
                var deltaX = _x - x;
                var deltaY = _y - y;

                $scroller[0].scrollLeft -= deltaX;

                x = _x;
                y = _y;
            }
        }

        function up(evt) {
            $window.off("mousemove", move);
            $window.off("mouseup", up);

            var deltaT = Date.now() - t;
            var deltaX = evt.pageX - x2;
            var deltaY = evt.pageY - y2;
            if (deltaT <= 300) {
                $scroller.stop().animate(
                    {
                        scrollTop: "-=" + deltaY * 3,
                        scrollLeft: "-=" + deltaX * 3,
                    },
                    500,
                    function (x, t, b, c, d) {
                        // easeOutCirc function from http://gsgd.co.uk/sandbox/jquery/easing/
                        return c * Math.sqrt(1 - (t = t / d - 1) * t) + b;
                    }
                );
            }

            t = 0;

            $container.removeClass("down");
        }

        function preventDefault(evt) {
            if (x2 !== evt.pageX || y2 !== evt.pageY) {
                evt.preventDefault();
                return false;
            }
        }

        function horizontalMouseWheel(evt) {
            evt = evt.originalEvent;
            var x = $scroller.scrollLeft();
            var max = $scroller[0].scrollWidth - $scroller[0].offsetWidth;
            var dir = evt.deltaX || evt.wheelDeltaX;
            var stop = dir > 0 ? x >= max : x <= 0;
            if (stop && dir) {
                evt.preventDefault();
            }
        }
    }

    addGrabCursonOnMouseDown(elem) {
        elem.mousedown((e) => {
            elem.css({
                cursor: "grab",
            });
        });
        elem.mouseup((e) => {
            elem.css({
                cursor: "auto",
            });
        });
    }
}
